.deleteBtn{
    background: #ad0101 !important;
    color: #fff !important;
}
.formError {
    font-size: 12px !important;
    color: #ff0000;
} 
.mdsInlineLabel {
    margin: 5px 15px 0 0;
    display: inline-block;
}

.mdsViewFile {
    min-width: 100px;
    margin: 4px 0 0 10px;
}

.mdsRemoveBtn {
    padding: 2px !important;
}

.mdsCountryInfo {
    float: right;
    margin-right: 3rem;
} 
.mdsCountryInfo span {
    font-size: 16px;
    font-weight: 500;
}
.saveButton{
    min-width: 150px !important;
    padding: 15px !important;
}
.searchButton{
    min-width: 112px !important;
    padding: 13.5px !important;
}
.mt4rem{
    margin-top: 4rem !important;;
}
.mdsAutocompleteGridNoLabel input {
    font-size: 14px !important;
    padding: 6px 4px !important;
    min-width: 80px !important;
}

.mdsAutocompleteGrid input {
    font-size: 14px !important;
    padding: 6px 4px !important;
    min-width: 80px !important;
}

.mdsAutocompleteGrid label {
    font-size: 1rem !important;
}
.mdsAutocompleteGrid fieldset legend{
    padding: 0 6px !important;
}
.mdsAutocompleteGrid fieldset legend span{
    padding-right: 10px !important;
}
.mdsAutocompleteGrid label{
    max-width: calc(90% - 32px) !important;
}
.border td,
.border th {
    border: 1px solid #dee2e6;
}
.termsData ul{
    padding-left: 25px;
}
.mdsDropdown{
    font-weight: 600; 
    font-size: 0.875rem;
    border-right-width: 0 !important;
    padding: 0;
    width: auto !important;
    min-width: auto !important;
}
.mdsDropdown a.ps-menu-button, .mdsDropdown a.ps-open{
    height: 35px !important;
    background:#1b1b1cf0 ;
    color: #fff !important;
    padding-left: 13px;
}  
.mdsDropdown .ps-submenu-content a.ps-menu-button{
    padding-left: 40px !important;
}
.mdsDropdown .ps-sidebar-container-test-id{
    background-color: transparent !important;
    color: #fff !important;
}
.mdsDropdown .ps-active{
    background: linear-gradient(195deg, #078149, #078149) !important;
}
.mdsDropdown li>.ps-menu-button:hover{
    background: #1b1b1cf0;
}
.rdt_TableCell{
    font-size: 14px !important;
}
.rdt_TableCol{
    font-size: 14px !important;
    font-weight: 600 !important;
}
.MuiPaper-root{
    overflow-y: visible !important;
}
.applicant_name{
    font-size: 14px;
    color: #000;
    font-weight: 400;
}